/* src/components/PrintLoadList.css */
.table-bordered {
    border: 1px solid black;
  }
  
  .table-bordered th,
  .table-bordered td {
    border: 1px solid black;
  }
  
  .excel-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .excel-table th,
  .excel-table td {
    padding: 4px;
    text-align: left;
  }
  
  .excel-table th {
    background-color: #f2f2f2;
  }
  
  .print-section {
    margin-bottom: 5px;
  }
  
  .print-container {
    padding: 5px; /* Add padding to give some space around the content */
    margin: 5px;  /* Add margin to ensure the container itself has space around it */
    background-color: white; /* Ensure the background is white for better readability */
  }
  
  