body{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.navbar {
    background-color: rgba(41, 128, 185, 0.1);
}

.card {
    border: none;
}

.boxshadow {
  box-shadow: 3px 3px 3px rgba(6, 48, 77, 0.3);
  padding: 2rem;
}

.boxshadowpaymentdetails {
  box-shadow: 0px 0px 0px 3px rgba(6, 48, 77, 0.2);
  padding: 1rem;
}

.table-bordered td {
  border: 1px solid rgb(120, 120, 120);
  padding: 8px; /* Optional for better spacing */
  font-size: 0.9rem;
}

.displayhead {
  color: green;
}

.resizetable td {
  padding: 0rem;
}
/* SidePanel.css */

.side-panel {
    width: 180px;
    min-width: 60px;
    height: calc(100vh - 1rem); /* Adjust height dynamically */
    /* position: fixed; */
    position: sticky;
    top: 4rem;        /* Starts sticking after scrolling 1rem */
    overflow-y: auto; /* Allow scrolling inside the panel if content overflows */
    /* top: 58px; */
    left: 0;
    margin-top: 5rem;
    transition: width 0.3s ease-in-out;
    /* box-shadow: 2px 0 3px rgba(41, 128, 185, 0.1); */
  }

  .side-panel.open {
    width: 160px; /* Set full width when open */
  }
  
  .side-panel:not(.open) {
    width: 60px; /* Set small width when closed */
  }
  
  .toggle-btn {
    margin-top: 20px;
    margin-left: 10px;
  }
  
  .panel-content {
    padding: 10px;
  }

  .container {
    transition: margin-left 0.3s ease-in-out;
  }
  
  .container-fluid .expanded {
    /* margin-left: 185px; */
  }

  .container-fluid .closed {
    /* margin-left: 64px; */
  }

  .sidep {
    display: block;  /* Makes the element take up the full width */
    width: 100%;     /* Ensures it stretches to the full width */
    padding: 10px;   /* Add some padding if needed */
    transition: background-color 0.3s ease; /* Smooth transition */
    cursor: pointer;
    border-radius: 1rem;
  }
  
  .sidep:hover {
    background-color: rgba(41, 128, 185, 0.1);
  }

  .selected {
    background-color: rgba(41, 128, 185, 0.1);
    /* Stays green when selected */
  }

  /* Default styles for icons */
.sidepicons {
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease; /* Smooth color transition */
}

.deleteicon {
  color: rgb(208, 18, 18); /* Sky Blue */
}

.editicon {
  color: rgb(35, 35, 192); /* Sky Blue */
}

.detailsicon {
  color: rgb(38, 181, 238)
}

.titilescolor {
  color: #0ea64d;
}

.theads th {
  color: #2980b9;
}

.card {
  background-color: rgba(41, 128, 185, 0.1);
}

.card.low-priority {
    background-color: #d4edda; /* Light green for low priority */
}

.card.meduim-priority {
    background-color: #fff3cd; /* Light yellow for medium priority */
}

.card.high-priority {
    background-color: #f8d7da; /* Light red for high priority */
}

/* Colors for specific icons */
.side-panel .panel-content h6:nth-of-type(1) .sidepicons {
  color: #1abc9c; /* Sky Blue */
}

.side-panel .panel-content h6:nth-of-type(2) .sidepicons {
  color: #eb3420; /* Red */
}

.side-panel .panel-content h6:nth-of-type(3) .sidepicons {
  color: #0e803d; /* Emerald Green */
}

.side-panel .panel-content h6:nth-of-type(4) .sidepicons {
  color: #ff9d00; /* Orange */
}

.side-panel .panel-content h6:nth-of-type(5) .sidepicons {
  color: #a119d7; /* Purple */
}

.side-panel .panel-content h6:nth-of-type(6) .sidepicons {
  color: #1abc9c; /* Turquoise */
}

/* formmodals open and close transition  */
.note-content {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.note-collapsed {
  max-height: 50px; /* or whatever the height is for the preview */
  opacity: 0.8;
}

.note-expanded {
  max-height: 500px; /* large enough for full content */
  opacity: 1;
}


/* for tasks */
.task-content {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.task-collapsed {
  max-height: 50px; /* or whatever the height is for the preview */
  opacity: 0.8;
}

.task-expanded {
  max-height: 500px; /* large enough for full content */
  opacity: 1;
}


  .dashboardicons {
    font-size: 2rem;
  }

  .dashboard {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  /* HomePage.css */

.homepage {
    display: flex;
}

.side-panel-open .container.expanded {
  /* margin-left: 240px; */
}

.sticky-column {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  z-index: -1;
  /* z-index: -1; */
}
.sticky-column + .sticky-column {
  left: 100px; /* Adjust this value according to your column width */
}
/* Add specific left positions for each sticky column */
.sticky-column:nth-child(2) {
  left: 130px;
}
.sticky-column:nth-child(3) {
  left: 189px;
}
.sticky-column:nth-child(4) {
  left: 248px;
}
.sticky-column:nth-child(5) {
  left: 290px;
}
.sticky-column:nth-child(6) {
  left: 340px;
}
.stickycolumn-inputssliding {
  left: 10rem;
}
